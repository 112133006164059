import { Address, Chain } from "wagmi";
import {
  avalanche,
  bsc,
  evmos,
  fantom,
  foundry,
  goerli,
  localhost,
  mainnet,
  moonbeam,
  polygon,
  polygonMumbai,
  dogechain,
  okc,
  base,
  pulsechainV4,
  pulsechain,
} from "@wagmi/chains";

import HELPER_ABI from "../models/abi/ERC721HelperFacet.json";
import addresses from "./contracts-config";
const helperAddress = addresses.helperAddress;
//import FENIX_ABI from "@/models/abi/FENIX_ABI";
//import { ethereumPoW, x1Devnet } from "@/libraries/chains";

console.log(HELPER_ABI);

export const helperContract = (contractChain?: Chain) => {
  //console.log("contractChain", contractChain)
  switch (contractChain?.id) {
    case foundry.id:
    //case localhost.id:
      return {
        //address: "0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9",
        address: addresses.helperAddress,
        abi: HELPER_ABI.abi,
        chainId: contractChain.id,
        decimals: 9,
      };
    default:
      return {
        address: helperAddress,
        abi: HELPER_ABI.abi,               
      };
  }
};
